import { NextPage } from 'next'
import Link from 'next/link'
import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import { StaticPageProps } from '../common/static-page-server-side-props/@types/static-page-props'
import { translate } from '../common/translatable/translatable'
import Footer from '../modules/static-pages/footer/footer.component'
import MyNavbar from '../modules/static-pages/my-navbar/my-navbar-component'
import { createLink, Routes } from '../routes'

const Custom404: NextPage<StaticPageProps> = ({ isLoggedIn, discountData }) => (
  <>
    <div className="bg-snack">
      <MyNavbar discountData={discountData} isLoggedIn={isLoggedIn} />
      <Container>
        <Row>
          <Col className="text-center text-purple">
            <img src="/svgs/404.svg" width={280} alt="brak strony" />
            <div className="font-size-54 font-size-mobile-31 font-weight-800 mt-30-px mb-30-px">
              {translate('Coś poszło nie tak')}
            </div>
            <div className="font-size-18 mb-100-px mb-mobile-60-px">
              {`${translate('Strona której szukasz nie istnieje')} `}
            </div>
            <div>
              <Link href={createLink(Routes.Pages_Home)}>
                <Button className="mb-80-px" color="primary">
                  {translate('Przejdź do strony głównej')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer hasNewsletter />
  </>
)

export default Custom404
